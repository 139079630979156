<template>
  <div>
    <el-card class="card">
      <el-dialog
        :title="`${$t(formData.id ? 'common.edit' : 'common.add')} ${$t(
          'financialCenter.received'
        )}`"
        :visible="editing"
        width="500px"
        close-on-click-modal
        close-on-press-escape
        @close="close"
      >
        <sui-form
          ref="form"
          :list="formList"
          :defaultdata="formData"
          @submit="submit"
        />
      </el-dialog>
      <el-empty
        v-if="!list.length"
        :description="this.$t('common.emptyTips')"
      ></el-empty>
      <div v-else class="main">
        <div v-for="card in list" :key="card.id" class="bank">
          <span class="opt">
            <el-button
              round
              icon="el-icon-edit"
              @click="edit(card)"
            ></el-button>
            <el-popconfirm
              :title="$t('common.confirmDelete')"
              @confirm="del(card)"
            >
              <template v-slot:reference>
                <el-button round icon="el-icon-delete"></el-button>
              </template>
            </el-popconfirm>
          </span>
          <div class="fl">
            <h2>
              {{ card.openBankName }}
            </h2>
            <p>{{ card.accountName }}</p>
            <p>{{ card.accountNo }}</p>
          </div>
          <img
            src="https://oss.sokogate.com/image/56ccc932719ab1862c42ed5e0bc5f3c8.png"
            class="fr"
          />
          <div class="clear"></div>
        </div>
      </div>
      <div class="btn">
        <el-button class="button" @click="add">{{
          $t("resident.AddBankCard")
        }}</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  AddBankCard,
  EditBankCard,
  DelBankCard,
  GetBankCardList,
} from "@/utils/api";
import SuiForm from "@/components/s-ui/form";

const defaultFormData = {
  openBankName: "",
  accountNo: "",
  accountName: "",
};

export default {
  components: {
    SuiForm,
  },
  data() {
    return {
      editing: false,
      formList: [
        {
          type: "input",
          name: "id",
          hidden: true,
        },
        {
          type: "input",
          name: "openBankName",
          label: "resident.openBankName",
          rules: [
            {
              required: true,
              trigger: "blur",
            },
          ],
        },
        {
          type: "input",
          name: "accountNo",
          label: "resident.accountNo",
          rules: [
            {
              required: true,
              trigger: "blur",
            },
          ],
        },
        {
          type: "input",
          name: "accountName",
          label: "resident.accountName",
          rules: [
            {
              required: true,
              trigger: "blur",
            },
          ],
        },
      ],
      formData: {},
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    submit(data) {
      console.log("submit:", data);
      (data.id ? EditBankCard : AddBankCard)(data).then(() => {
        this.$message({
          type: "success",
          message: this.$t(
            data.id ? "common.updateSuccess" : "common.createSuccess"
          ),
        });
        this.getList();
        this.close();
      });
    },
    add() {
      // console.log("formData:", this.formData);
      this.editing = true;
      this.$nextTick(() => {
        // this.$refs["form"].setForm(this.formData);
        this.formData = defaultFormData;
      });
    },
    edit(rowData) {
      console.log("edit-rowData:", rowData);
      // this.formData = rowData;
      this.editing = true;
      this.$nextTick(() => {
        // this.$refs["form"].setForm(rowData);
        this.formData = rowData;
      });
    },
    del(rowData) {
      DelBankCard({
        id: rowData.id,
      }).then(() => {
        this.getList();
        this.close();
      });
    },
    getList() {
      GetBankCardList({}).then((res) => {
        this.list = res.data.rows;
      });
    },
    close() {
      this.editing = false;
      this.formData = {};
      this.$refs["form"].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 82vh;
  position: relative;
}
.main {
  margin-top: 70px;
  margin-left: 90px;
}
.bank {
  width: 350px;
  height: 150px;
  background: #e4efff;
  float: left;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 15px;
  position: relative;

  .fl {
    max-width: 250px;
  }

  .opt {
    display: none;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  &:hover {
    .opt {
      display: inline-block;
    }
  }
}
.bank h2 {
  font-size: 18px;
  margin-top: 25px;
  margin-left: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.bank p {
  margin-top: 12px;
  margin-left: 30px;
  font-size: 12px;
  color: gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bank img {
  width: 58px;
  height: 58px;
  margin-top: 17px;
  margin-right: 35px;
}
.btn {
  text-align: center;
  position: absolute;
  bottom: 15px;
  width: 100%;
}
.btn .button {
  width: 380px;
  height: 80px;
  margin-top: 10px;
  background: #ff0a0a;
  color: #fff;
  font-size: 20px;
  border-radius: 10px;
}
</style>